import React, { useState, useEffect } from "react"
import { States, ErrorMessages } from "../../constants"
import { getAgencies } from "../../services/find-agencies-service"
import { useForm } from "react-hook-form"
import { TrvDropdown } from "trv-ebus-tcom-reactcomponents"
import { TdsAlert } from "@trv-tds/react"
import getFullStateName from "../../utilities/getFullStateName"
import "./scss/main.scss"
const findAnAgent = ({ location }) => {
  const [isClient, setIsClient] = useState(false)
  const queryParams = new URLSearchParams(location.search)
  const selectedState = queryParams.get("state")

  const [agenciesData, setAgenciesData] = useState(null)
  const [fullStateName, setFullStateName] = useState(selectedState)
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false)
  const [isServiceError, setIsServiceError] = useState(false)

  useEffect(() => {
    setIsClient(true)
    if (selectedState) {
      serviceCallHandler(selectedState)
    }
  }, [])

  const onDropdownChange = (event) => {
    setValue(state, event.target.value)
  }
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const serviceCallHandler = async (stateCode) => {
    try {
      setIsSubmissionInProgress(true)
      const agencies = await getAgencies(stateCode)
      if (agencies) {
        setAgenciesData(agencies)
        setIsServiceError(false)
        const stateName = getFullStateName(stateCode)
        setFullStateName(stateName)
      } else {
        setIsServiceError(true)
      }
    } catch (error) {
      setIsServiceError(true)
      setAgenciesData(null)
    }
    setIsSubmissionInProgress(false)
  }

  const onSubmit = (form) => {
    serviceCallHandler(form.state)
  }

  return isClient ? (
    <>
      {isServiceError && (
        <TdsAlert type="error">{ErrorMessages.Service_Error}</TdsAlert>
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="tds-margin-top-large"
        noValidate>
        <div className="tds-row tds-margin-top-xx-large">
          <div className="tds-col-xl-7">
            <TrvDropdown
              register={register}
              name="state"
              label="YOUR STATE"
              options={States}
              setValue={setValue}
              required={true}
              hint="Select Your State..."
              defaultValue={selectedState == null ? "" : selectedState}
              errors={errors.state}
              onChange={onDropdownChange}
              validityMessages={{
                required: ErrorMessages.Empty_State
              }}
            />
          </div>
        </div>
        <button
          className="tds-button--primary tds-margin-top-x-large find-btn"
          type="submit"
          aria-label="Find A General Agent"
          aria-busy={isSubmissionInProgress}>
          Find A General Agent
        </button>
      </form>

      {agenciesData && (
        <div className="tds-margin-top-xxx-large">
          <hr />
          <p className="tds-margin-top-xx-large">
            Here's who we work with in {fullStateName}
          </p>
          {agenciesData.map((agent, i) => (
            <div key={i} className="tds-margin-top-x-large">
              {agent.AgencyName && (
                <h3 className="agent-title">
                  <a href={`http://${agent.URL}`} target="_blank">
                    {agent.AgencyName.toUpperCase()}
                  </a>
                </h3>
              )}
              {agent.AgencyAddress && <div>{agent.AgencyAddress}</div>}
              {agent.City && agent.State && agent.ZipCode && (
                <div>
                  {agent.City}, {agent.State} {agent.ZipCode}
                </div>
              )}
              {agent.Phone && (
                <div>
                  <a href={`tel:${agent.Phone}`}>{agent.Phone}</a>
                </div>
              )}
              {agent.Email && (
                <div>
                  <a href={`mailto:${agent.Email}`}>{agent.Email}</a>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {!agenciesData && selectedState && (
        <div className="tds-margin-top-xxx-large">
          No agents were found in {fullStateName}.
        </div>
      )}
    </>
  ) : null
}

export default findAnAgent

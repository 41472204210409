import axios from "axios"
import { store } from "../state/store"

const TrvEncyption = require('@travelers/encryption')
const keyStr = 'NORTHFIELD'
const trvProvider = TrvEncyption.cryptoProvider(keyStr)

const getAgencies = async (stateCode) => {
  try {
    const state = store.getState()
    const headers = {
     "Content-type": "application/json",
     'client_id': trvProvider.decrypt(state.config.FIND_AN_AGENT_CLIENT_ID), 
     'client_secret': trvProvider.decrypt(state.config.FIND_AN_AGENT_CLIENT_SECRET)
    }

    let response = await axios.get(
      `${state.config.FIND_AN_AGENT_SERVICE_URL}?stateCode=${stateCode}`,
      {
        headers: headers
      }
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
  return null
}

export { getAgencies }
